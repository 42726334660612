* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: hsla(197, 100%, 76%, 1);

  background: linear-gradient(
    225deg,
    hsla(197, 100%, 76%, 1) 0%,
    hsla(324, 100%, 76%, 1) 100%
  );

  background: -moz-linear-gradient(
    225deg,
    hsla(197, 100%, 76%, 1) 0%,
    hsla(324, 100%, 76%, 1) 100%
  );

  background: -webkit-linear-gradient(
    225deg,
    hsla(197, 100%, 76%, 1) 0%,
    hsla(324, 100%, 76%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#84DCFF", endColorstr="#FF83CD", GradientType=1 );

  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.header-container {
  margin-top: 2em;
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 5rem;
}

.header-logo {
  width: 33.3%;
  display: flex;
  justify-content: center;
}

.h-l-btn {
  margin-left: 10px;
  border: none;
  background-image: linear-gradient(to left, #ea5573, #ea8f5e, #eaa057);
  color: #fff;
  border-radius: 20em;
  padding: 6px 12px;
}

/* Nav */
.header-nav {
  width: 33.3%;
  align-items: center;
  font-family: sans-serif;
}

ul {
  display: flex;
  width: 100%;
  justify-content: center;
}

li {
  list-style-type: none;
  margin-left: 26px;
}

a {
  text-decoration: none;
  color: #616160;
}

/* Header button */
.header-buttons {
  font-family: sans-serif;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.h-b-signBtn {
  background-color: transparent;
  border: none;
  outline: none;
}

.h-b-waitBtn {
  margin-left: 12px;
  padding: 12px;
  color: #fff;
  background-color: #4977ea;
  border: 1px solid #4977ea;
  border-radius: 0.3em;
  cursor: pointer;
  transition: 0.3s ease all;
}

.h-b-waitBtn:hover {
  background-color: transparent;
  color: #4977ea;
}

/* Main */
.main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.m-lft-content {
  align-self: center;
  justify-content: center;
}

.m-rgt-content {
  width: 30%;
}
h1.m-lft-head {
  text-align: center;
  font-size: 57px;
  width: 57%;
  margin-top: 92px;
}

.m-lft-para {
  text-align: center;
  width: 50%;
  margin: 6px 0px 6px 33px;
  font-family: sans-serif;
  color: #495057;
}

.m-l-inputs {
  margin-top: 20px;
}

input[type="email"] {
  max-width: 60%;
  width: 100%;
  padding: 10px;
  outline: none;
  height: 60px;
  border: none;
  border-radius: 8px;
  font-family: sans-serif;
}

.ml-i-btn {
  width: 154px;
  height: 60px;
  border-radius: 8px;
  border: 2px solid #4977ea;
  font-family: sans-serif;
  color: #fff;
  background-color: #4977ea;
  font-size: 15px;
  cursor: pointer;
  transition: 0.3s ease all;
}

.ml-i-btn:hover {
  background-color: transparent;
  color: #4977ea;
}

.m-lft-warning {
  margin: 10px 0px 0px 33px;
  font-family: sans-serif;
  color: #495057;
}

.ml-w-span {
  position: relative;
  margin-right: 10px;
  top: 6px;
}

.img-stack {
  position: relative;
  bottom: 157px;
  left: -90px;
}

.m-f-img {
  position: absolute;
  z-index: 1;
}

.m-f-2 {
  transform: rotate(-10deg);
  position: absolute;
  z-index: 0.9;
}

.m-f-3 {
  transform: rotate(-20deg);
  position: absolute;
  z-index: 0.8;
}

.m-f-4 {
  transform: rotate(-30deg);
  position: absolute;
  z-index: 0.7;
}

/* footer */
.footer-container {
  display: flex;
  margin-top: 120px;
  justify-content: center;
  align-items: center;
}

.social-text {
  margin-left: 50px;
  width: 50%;
  display: flex;
  font-family: sans-serif;
}

.stext {
  margin-left: 14px;
}

.helpdesk {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-hd {
  margin-left: 8px;
}

.hd-2 {
  font-weight: bolder;
  font-family: sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
